<template>
  <div class="home">
    <el-container>
      <el-header>
        <div class="header">
          <div class="deviceBack">
            <i class="el-icon-arrow-left" @click="back()"></i>
          </div>
          <div class="header_title">我的订单-佛山市灏海餐饮管理有限公司</div>
        </div>
      </el-header>
      <el-main>
        <div class="orderPart">
          <div class="orderPartType">
            <div
              class="orderType"
              :class="{'activeType': (nowType == nameItem.id) }"
              v-for="nameItem in nameOptions"
              :key="nameItem.id"
              @click="getType(nameItem.id)"
            >{{ nameItem.name }}</div>
          </div>
          <div class="orderMain" @scroll="scrollPart($event)">
            <div
              class="orderList"
              v-for="(item, index) in orderList"
              :key="index"
              @click="jumpOrderDetail(item.id)"
            >
              <div class="orderTop">
                <div class="orderSn">订单编号: {{ item.id }}</div>
                <div class="orderStatus">{{ item.status === -1 ? "待支付" : item.status === 0 ? "待取货" : "已完成  " }}</div>
              </div>
              <div class="orderCenter">
                <div class="orderCellList">
                  <div class="cellList">
                    <div class="cellName">付款人：</div>
                    <div class="cellValue">{{ item.draweeName }}</div>
                  </div>
                  <div class="cellList">
                    <div class="cellName">付款人电话：</div>
                    <div class="cellValue">{{ item.draweePhone}}</div>
                  </div>
                </div>
              </div>
              <div class="orderBottom">
                <el-button
                  @click="moreBuy(item)"
                  type="danger"
                  size="mini"
                  class="leftBtn"
                  v-show="item.status === -1"
                >重新支付</el-button>
                <div class="totalPrice">总金额：{{ item.totalCost }}</div>
              </div>
            </div>
            <div class="noDataShow" v-show="orderList.length === 0">
              暂无数据
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataForm: {
        buyerId:"",
      },
      btnloading: false,
      nowType: 2,
      pageIndex: 1,
      pageSize: 8,
      totalPage: 0,
      nameOptions: [
        {id: 2, name: "全部"},
        {id: -1, name: "待支付"},
        {id: 0, name: "待取货"},
        {id: 1, name: "已完成"},
      ],
      orderList:[],
    }
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    init() {
      this.nowType = this.$route.query.type ?? 2;
      this.getType(this.nowType)
    },
    // 获取当前选择状态
    getType(type) {
      this.nowType = type;
      this.getDataList(type);
    },
    getDataList(type) {
      let postType = null;
      if (type) {
        this.nowType = type;
        if (type === 2) {
          postType = null
        } else {
          postType = type;
        }
      } else {
        postType = type;
      }
      let getBuyer = JSON.parse(sessionStorage.getItem("store"));
      this.$http.httpGet(`/mobile/onlineShop/listRetail`,{
        buyerId: getBuyer.buyerId,
        status: postType,
        isOnline: 1,
        page: this.pageIndex,
        limit: this.pageSize,
      }).then(data => {
        if (data && data.code === 0) {
          this.orderList = data.page.list;
          this.totalPage = data.page.totalPage;
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    scrollPart(event) {
      const scrollDistance = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight;
      // 判断是否到底，可以加载下一页
      if (scrollDistance <= 0) {
        // 判断是否全部加载完成
        if (this.totalPage <= this.pageIndex) {
          this.$message.warning("已经见底了 ～");
        } else {
          // 加载下一页方法
          this.loadMore();
        }
      }
    },
    // 加载更多
    async loadMore() {
      if (this.totalPage > this.pageIndex) {
        this.pageIndex = this.pageIndex + 1;
        // 根据获取的数据请求符合条件的工单
        let postType = this.nowType === 2 ? null : this.nowType;
        let getBuyer = JSON.parse(sessionStorage.getItem("store"));
        await this.$http.httpGet('/mobile/onlineShop/listRetail', {
          buyerId: getBuyer.buyerId,
          status: postType,
          isOnline: 1,
          page: this.pageIndex,
          limit: this.pageSize,
        }).then(res => {
          if (res && res.code === 0) {
            this.orderList = this.orderList.concat(res.page.list);
            this.totalPage = res.page.totalPage;
          } else {
            this.orderList = this.orderList;
            this.totalPage = 0;
            this.$message.error('获取数据失败: ' + res.msg)
          }
        }).catch(() => {
          this.loading = false;
        })
      }
    },

    // 跳转到订单详情
    jumpOrderDetail(id) {
      // this.$cookies.set()
      this.$router.replace({
        name: "orderDetails",
        query: {
          id: id,
          type: this.nowType
        }
      });
    },
    moreBuy(getData) {
      this.btnloading = true;
      this.$http.httpPost(`mobile/onlineShop/reSave`, {
        id: getData.id,
        receivableCost: getData.receivableCost,
        totalCost: getData.totalCost,
        // returl: process.env.VUE_APP_PAY_URL + "payStatus.html?t=" + (+new Date()),
      }).then( data => {
        this.btnloading = false;
        if (data && data.code === 0) {
        } else {
          this.$message.error(data.msg);
        }
      }).catch(() => {
        this.btnloading = false;
      });
    },
    back() {
      this.$router.replace({ name: "mine" });
    }
  },
}
</script>

<style scoped lang="scss">
.home {
  height: 100%;
  .el-container {
    height: 100%;
    .el-header {
      padding: 0;
      margin: 0;
      height: auto !important;
      background: #02A7F0;
      .header_title {
        height: 47px;
        text-align: center;
        color: white;
        line-height: 47px;
        font-size: 13px;
        .dropOut {
          position: absolute;
          right: 5px;
          top: 0;
          padding: 0 5px;
          font-size: 12px;
          color: #fff;
        }
      }

      .header_logo {
        text-align: center;
        padding: 2vh 0;
        .logo {
          height: 15vh;
          display: block;
          margin: 0 auto;
        }
      }
    }
    .el-main {
      padding: 0 !important;
      .main{
        .mescroll {
          .scrollList {
            position: relative;
            overflow: hidden;
            border-bottom: 1px #efefef solid;
            display: flex;
            padding: 10px 15px;
            .leftWay {
              flex: 1;
              p {
                font-size: 14px;
                line-height: 1.6;
              }
            }
            .rightWay {
              width: 85px;
              position: relative;
              i {
                display: inline-block;
                float: right;
                line-height: 44px;
              }
              p {
                display: inline-block;
                font-size: 14px;
                line-height: 44px;
                float: left;
                padding: 0 10px 0 0;
              }
              .red {
                color: #f00;
              }
              .green {
                color: #17b3a3;
              }
              .normal {
                color: #298FFF;
              }
            }
          }
          .NodataList {
            padding: 30px 0;
            font-size: 14px;
            text-align: center;
            color: #5e5e5e;
          }
        }
        .box-card {
          margin: 0 0 15px;
          .clearfix span{
            font-weight: 700;
            font-style: normal;
            font-size: 16px;
            color: #5E5E5E;
          }

          .el-form-item {
            height: 35px;
            margin-bottom: 10px !important;
            ::v-deep .el-form-item__label {
              font-size: 14px;
              padding: 0 5px 0 0;
              width: 95px !important;
            }

            .qxyy{
              float: right;
            }

            .num_span {
              font-family: 'PingFang SC ', 'PingFang SC', sans-serif;
              font-weight: 700;
              font-style: normal;
              color: #0297D7;
            }
          }
        }
      }
    }
    .el-footer {
      background: #fff;
      color: #5e5e5e;
      height: 55px !important;
      border-top: 1px #efefef solid;
      .bottom {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        .bot_business, .bot_my, .member{
          flex: 1;
          text-align: center;
          .el-icon-circle-plus, .el-icon-s-order, .el-icon-user{
            font-size: 20px;
            margin: 8px 0 2px;
          }
          span {
            font-size: 13px;
            display: block;
            text-align: center;
            line-height: 1.6;
          }
        }
        .active {
          i, span {
            color: #298FFF;
          }
        }
      }
    }
  }
}
</style>
